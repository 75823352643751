var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('span',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.props.label))]),(_vm.props.tooltip)?_c('div',{staticClass:"tooltip"},[_c('div',{staticClass:"info-container"}),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.props.tooltip))])]):_vm._e()]),_c('br'),_c('div',{staticClass:"input-wrapper"},[_c('a-table',{staticClass:"ant-table",staticStyle:{"width":"100%"},attrs:{"data-source":_vm.props.tableData,"columns":_vm.props.tableColumns,"scroll":_vm.getWindowWidth(),"prop":_vm.props.elementId,"id":_vm.props.elementId,"pagination":_vm.props.pagination},on:{"change":function($event){return _vm.updateHeight()}},scopedSlots:_vm._u([{key:"filterDropdown",fn:function(ref){
        var setSelectedKeys = ref.setSelectedKeys;
        var selectedKeys = ref.selectedKeys;
        var confirm = ref.confirm;
        var clearFilters = ref.clearFilters;
        var column = ref.column;
return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:(function (c) { return (_vm.searchInput = c); }),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"100%","margin-bottom":"8px","display":"block"},attrs:{"placeholder":("Search " + (column.dataIndex)),"value":selectedKeys[0]},on:{"change":function (e) { return setSelectedKeys(e.target.value ? [e.target.value] : []); },"pressEnter":function () { return _vm.handleSearch(selectedKeys, confirm, column.dataIndex); }}}),_c('a-button',{staticClass:"ant-btn-table",staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"icon":"search","size":"small"},on:{"click":function () { return _vm.handleSearch(selectedKeys, confirm, column.dataIndex); }}},[_vm._v(" Search ")]),_c('a-button',{staticClass:"ant-btn-table",staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":function () { return _vm.handleReset(clearFilters); }}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_c('div',[_c('div',[_vm._l((text
                  .toString()
                  .split(
                    new RegExp(("(?<=" + _vm.searchText + ")|(?=" + _vm.searchText + ")"), 'i')
                  )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2)])]):[_c('div',{staticClass:"editable-cell"},[(_vm.editableData.includes(column.dataIndex))?_c('div',[(
                  _vm.currentlyEditable === record.key + ',' + column.dataIndex
                )?_c('div',[_c('a-input',{staticClass:"editable-cell-input-wrapper",attrs:{"id":"editCell"},model:{value:(_vm.props.tableData[record.key - 1][column.dataIndex]),callback:function ($$v) {_vm.$set(_vm.props.tableData[record.key - 1], column.dataIndex, $$v)},expression:"props.tableData[record.key - 1][column.dataIndex]"}}),_c('a-icon',{staticClass:"editable-cell-icon",attrs:{"type":"check"},on:{"click":function($event){return _vm.save(record.key, column.dataIndex, text)}}})],1):_c('div',[_vm._v(" "+_vm._s(text)+" "),_c('a-icon',{staticClass:"editable-cell-icon",attrs:{"type":"edit"},on:{"click":function($event){return _vm.edit(record.key, column.dataIndex, text)}}})],1)]):_c('div',[_vm._v(" "+_vm._s(text)+" ")])])]]}},{key:"action",fn:function(text, record){return _c('span',{},[_vm._l((_vm.props.actionColumns),function(column,index){return [_c('a',{key:column.dataIndex,attrs:{"value":column.title},on:{"click":function () { return _vm.resolveAction(column.action, column.journey, record); }}},[_vm._v(" "+_vm._s(column.title)+" "),(
                !index == _vm.props.actionColumns.length - 1 ||
                (index == _vm.props.actionColumns.length - 1 && _vm.props.deleteable)
              )?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e()],1)]}),(_vm.props.deleteable)?[_c('a-popconfirm',{attrs:{"title":"Confirm Delete?"},on:{"confirm":function($event){return _vm.deleteRow(record.key)}}},[_c('a',{key:"delete",attrs:{"value":"Delete"}},[_vm._v(" Delete ")])])]:_vm._e()],2)}}])}),(_vm.editableTable)?_c('a-button',{staticClass:"editable-add-btn",on:{"click":_vm.handleAdd}},[_vm._v("Add Row")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }